<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{
          $t('hat.clockRecord.electronicFence')
        }}</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <div :class="$style.form">
          <a-form-model ref="ruleForm" :class="$style.formWrap">
            <a-form-model-item>
              <a-input
                style="width: 240px"
                v-model="input"
                @input.stop="() => handleSearch()"
                :placeholder="$t('hat.electronFence.electronFenceName')"
              >
                <a-icon slot="prefix" type="search" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                v-if="$p.action('CREATE')"
                type="primary"
                icon="plus"
                size="small"
                @click="addFenceHandle()"
                >{{ $t('hat.electronFence.add') }}</a-button
              >
            </a-form-model-item>
          </a-form-model>
        </div>
        <div :class="$style.gridBox">
          <div :class="$style.item" v-for="item in list" :key="item.geofenceId">
            <span :class="$style.title">{{ item.geofenceName }}</span>
            <img :src="defaultImageSrc(item)" />
            <div :class="$style.buttonGroup">
              <a-tooltip>
                <template slot="title">
                  {{ $t('hat.deviceManagement.edit') }}
                </template>
                <a
                  v-if="$p.action('UPDATE')"
                  @click.stop="handleUpdateFence(item)"
                  ><a-icon :class="$style.icon" type="edit"
                /></a>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  {{ $t('hat.electronFence.preview') }}
                </template>
                <a @click.stop="openOverview(item)"
                  ><a-icon :class="$style.icon" type="file-search"
                /></a>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  {{ $t('hat.electronFence.copy') }}
                </template>
                <a v-if="$p.action('UPDATE')" @click.stop="handleCopy(item)"
                  ><a-icon :class="$style.icon" type="copy"
                /></a>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  {{ $t('enterpriseManagement.camera.delete.txt') }}
                </template>
                <a v-if="$p.action('DELETE')" @click.stop="handleDelete(item)"
                  ><a-icon :class="[$style.icon, $style.red]" type="delete"
                /></a>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div :class="$style.pagination">
          <a-pagination
            v-model="pagination.current"
            :page-size-options="pageSizeOptions"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :showTotal="total => $t('todo.flow.total', { total })"
            show-size-changer
            @change="handleChange"
            @showSizeChange="onShowSizeChange"
          />
        </div>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import dayjs from 'dayjs';
import {
  getFenceList,
  insertFence,
  updateFence,
  removeFence,
  copyFence,
  deleteBatch,
} from '@/services/smart-hat/geofence';
import { getMyDevice } from '@/services/smart-hat/device';
import { createFormModal, createModal } from '@triascloud/x-components';
import FenceForm from './components/form.vue';
import FenceMap from './components/form-map.vue';
import Tip from '@/components/tip';
import { CHART_DIALOG_KEY, funAMap } from './components/util';

@Component({
  components: {
    LayoutContent,
  },
})
export default class ElectronFence extends Vue {
  mapKey = process.env.VUE_APP_AMAP_WEB_API_KEY;
  list = [];
  input = '';
  loading = false;
  mounted() {
    this.ipSearch();
    this.getListData();
  }

  ipLngLat = '';
  async ipSearch() {
    const _self = this;
    const AMap = await funAMap({
      plugins: ['AMap.CitySearch'],
    });

    AMap.plugin('AMap.CitySearch', function() {
      const citySearch = new AMap.CitySearch();
      citySearch.getLocalCity(function(status, result) {
        if (status === 'complete' && result.info === 'OK') {
          // 查询成功，result即为当前所在城市信息
          const rect = new AMap.Rectangle({
            bounds: result.bounds,
          });
          _self.ipLngLat = `${rect.getCenter().lng},${rect.getCenter().lat}`;
        }
      });
    });
  }

  defaultImageSrc(item) {
    return `https://restapi.amap.com/v3/staticmap?location=${this.defaultLagLat(
      item,
    )}&size=400*200&zoom=14&key=${process.env.VUE_APP_AMAP_WEB_API_KEY}`;
  }
  defaultLagLat(item) {
    if (item.lng && item.lat) {
      return `${item.lng},${item.lat}`;
    } else {
      return this.ipLngLat;
    }
  }

  async getMyDevice() {
    await getMyDevice();
  }

  handleDelete(record) {
    const $t = this.$t;
    createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>
              {$t('enterpriseManagement.camera.delete.title')}
              {record.geofenceName}
            </span>
          </template>
          <span slot="subTxt">{$t('hat.electronFence.afterDeletion')}</span>
        </Tip>
      ),
      {
        title: $t('common.tips.prompt'),
        onOk: async () => {
          this.$message.success('删除成功！');
          await removeFence(record.geofenceId);
          this.getListData();
        },
      },
    );
  }

  async handleCopy(record) {
    await copyFence([record.geofenceId]);
    this.$message.success('操作成功！');
    this.getListData();
  }

  async getListData() {
    this.loading = true;
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.input) {
      params.input = this.input;
    }
    const { records, current, size, total } = await getFenceList(params);
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
    this.list = records;
    this.loading = false;
  }
  pageSizeOptions = ['12', '24', '48'];
  pagination = {
    pageSize: 12,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };

  handleChange(current) {
    this.pagination.current = current;
    this.getListData();
  }
  onShowSizeChange(current, pageSize) {
    this.pagination.pageSize = pageSize;
    this.pagination.current = current;
    this.getListData();
  }

  async openOverview(obj) {
    await this.editZoneMap({
      id: obj.geofenceId,
      title: obj.geofenceName,
      permission: obj.permission,
      type: CHART_DIALOG_KEY.READ,
    });
  }

  async addFenceHandle() {
    try {
      const title = this.$t('hat.electronFence.add');
      const result = await createFormModal(() => <FenceForm />, {
        width: '440px',
        title: title,
        maskClosable: false,
      });
      if (result) {
        const { geofenceId, permission } = await insertFence(result);
        this.getListData();
        await this.editZoneMap({
          id: geofenceId,
          title,
          permission,
        });
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }

  async handleUpdateFence(obj) {
    const title = this.$t('hat.electronFence.editElectronFence');
    const result = await createFormModal(() => <FenceForm editData={obj} />, {
      width: '440px',
      title,
      maskClosable: false,
    });

    if (result) {
      const { permission } = await updateFence(result);
      this.getListData();
      await this.editZoneMap({
        id: obj.geofenceId,
        title,
        permission,
      });
    }
  }

  /** @name 编辑电子围栏地图 */
  async editZoneMap({ id, title, permission, type = CHART_DIALOG_KEY.UPDATE }) {
    const editModal = createModal(
      () => (
        <FenceMap
          id={id}
          permission={permission}
          type={type}
          onFinally={() => {
            editModal.handleClose();
            this.getListData();
          }}
        />
      ),
      {
        width: 1000,
        title,
        maskClosable: false,
        className: this.$style.fenceMap,
      },
    );
  }

  get columns() {
    const $t = this.$t;
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: $t('hat.clockRecord.index'),
        align: 'center',
        width: 80,
      },
      {
        width: 300,
        align: 'left',
        title: $t('hat.electronFence.name'),
        ellipsis: true,
        dataIndex: 'geofenceName',
        // customRender: (text, row, index) => <span>{index + 1}</span>,
      },
      {
        align: 'left',
        title: $t('hat.electronFence.activeArea'),
        dataIndex: 'allowZone',
      },
      {
        title: $t('hat.electronFence.forbiddenRegion'),
        dataIndex: 'prohibitZone',
      },
      {
        title: $t('hat.electronFence.available'),
        dataIndex: 'usable',
        ellipsis: true,
        width: 300,
        customRender: text => text + '台设备',
      },
      {
        title: $t('hat.electronFence.editTime'),
        dataIndex: 'updateTime',
        customRender: txt => dayjs(txt).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: $t('enterpriseManagement.camera.tableColumn.operation'),
        // width: 300,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  handleSearch() {
    this.pagination.current = 1;
    this.getListData();
  }
  async handleUnbind(record) {
    const { geofenceId, geofenceName } = record;
    const text = `确认删除${geofenceName}？`;
    const tips = '删除后建议重新配置关联设备和考勤组中的电子围栏';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            try {
              await deleteBatch(String(geofenceId));
              this.$message.success('电子围栏删除成功');
              this.getListData();
            } catch (error) {
              this.$message.warn('电子围栏删除失败');
            }
          },
        },
      );
    } catch {
      return false;
    }

    // try{
    //   await deleteBatch(String(geofenceId));
    //   this.$message.success('电子围栏删除成功');
    //   this.getListData();
    // }catch(error){
    //   this.$message.warn('电子围栏删除失败');
    // }
  }
}
</script>
<style lang="less" module>
.form {
  position: absolute;
  z-index: 999;
  width: calc(100% - 60px);
  padding-top: 20px;
  padding-bottom: 6px;
  background-color: var(--main-bg);
  .formWrap {
    display: flex;
    justify-content: space-between;
  }

  :global(.ant-form) {
    display: flex;
    align-items: center;
  }

  .btn {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;

  .title {
    font-size: 16px;
  }

  .manageContent {
    padding: 0 28px 20px 28px;
    min-width: 850px;
    // height: 100%;
    // .table {
    //   height: 94%;
    //   padding-bottom: 10px;
    // }
    :global {
      .ant-table-wrapper .ant-spin-container .ant-table-body,
      .ant-table-wrapper .ant-table-scroll .ant-table-body {
        flex: 1;
        overflow: auto !important;
        max-height: none !important;
      }
    }
  }
}

.fenceMap {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
.gridBox {
  padding: 70px 0 36px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  .item {
    position: relative;
    font-size: 0;
    img {
      width: 100%;
      height: 200px;
      border-radius: 10px;
    }
    &:hover {
      .buttonGroup {
        opacity: 1;
      }
    }
  }
  .title {
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-size: 14px;
    max-width: 150px;
    padding: 2px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
  }
  .buttonGroup {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 30px;
    border-radius: 0 0 10px 10px;
    background-color: rgba(255, 255, 255, 0.5);
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }
  .icon {
    font-size: 16px;
  }
  .red {
    color: var(--danger);
  }
}
.pagination {
  position: absolute;
  right: 20px;
  bottom: 2px;
  background-color: var(--main-bg);
  padding: 2px;
  border-radius: 10px;
}
</style>
